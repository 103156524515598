<template>
  <div>
    <crud-table
      ref="crudTable"
      :list-items="listItems"
      :is-editable="false"
      :is-disabled-deleted-filter="true"
      :show-brands-filter="true"
      :brand-list="brandList"
      heading="Driver types"
      endpoint="driver-types"
      row-click-path="/tables/driver-types"
      @create="createDriverType()"
      @edit="data => editDriverType(data)"
      @delete="id => deleteDriverType(id)"
    />

    <edit-dialog
      :show.sync="editDialog.display"
      :max-width="'600'"
      @dialogConfirm="editDialog.saveFunction">
      <template>
        <v-form
          ref="driverTypeForm"
          v-model="valid"
          lazy-validation>
          <v-container grid-list-xl>
            <v-layout
              row
              wrap>
              <v-flex
                xs12
                sm6>
                <v-text-field
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  v-model="editDialog.data.title"
                  :rules="[v => Boolean(v) || 'Please provide a title']"
                  type="text"
                  label="Title"
                />
              </v-flex>
              <v-flex
                xs12
                sm6>
                <v-text-field
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  v-model="editDialog.data.description"
                  type="text"
                  label="Description"
                />
              </v-flex>
              <v-flex
                xs6>
                <v-select
                  v-if="brandList.length"
                  :items="brandList"
                  :rules="[v => Boolean(v) || 'Please provide a brand']"
                  v-model="editDialog.data.brand"
                  item-text="name"
                  item-value="id"
                  label="Brand"
                />
              </v-flex>
              <v-flex
                xs6>
                <v-select
                  :disabled="!driverTypeList.length"
                  :items="driverTypeList"
                  :rules="[v => Boolean(v) || 'Please provide a driver type']"
                  v-model="editDialog.data.ridesFilter"
                  item-value="id"
                  item-text="title"
                  label="Driver types"
                  multiple
                />
              </v-flex>
              <v-flex
                xs6>
                <v-text-field
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  v-model="editDialog.data.icon"
                  type="text"
                  label="Icon"
                />
              </v-flex>
              <v-flex
                xs6>
                <InputHotKeyWrapper>
                  <v-text-field
                    :max-length="TEXT_FIELD_MAX_LENGTH"
                    v-model="editDialog.data.price"
                    type="number"
                    label="Price"
                  />
                </InputHotKeyWrapper>
              </v-flex>
              <v-flex
                xs6>
                <v-text-field
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  v-model="editDialog.data.minHoursPerRide"
                  type="time"
                  label="Ride time (min)"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </template>
    </edit-dialog>
  </div>
</template>

<script>
import lodash from 'lodash'
import CrudTable from '../../../modules/admin/CrudTable.vue'
import Request from '../../../helpers/request'
import Brands from '../../../helpers/brands'
import { TEXT_FIELD_MAX_LENGTH } from '../../../constants/common'

export default {
  components: { CrudTable },
  mixins: [Request, Brands],
  data () {
    return {
      valid: true,
      listItems: [
        { text: 'ID', value: 'id' },
        { text: 'Title', value: 'title' },
        { text: 'Description', value: 'description' },
        { text: 'Brand', value: 'brand', textValue: 'name' },
        { text: 'Price', value: 'price' },
        { text: 'Ride time (min)', value: 'minHoursPerRide', textValue: 'time' },
        { text: 'Icon', value: 'icon' },
        { text: 'Created at', value: 'createdAt' },
        { text: 'Modified at', value: 'modifiedAt' },
        { text: '', value: null }
      ],
      TEXT_FIELD_MAX_LENGTH,
      loading: true,
      data: [],
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {}
      },
      brandList: [],
      driverTypeList: []
    }
  },
  watch: {
    'editDialog.data.brand': {
      async handler (newValue, oldValue) {
        if (newValue && !lodash.isEqual(newValue, oldValue)) {
          const { data } = await this.request('GET', `/driver-types`, { params: { brands: newValue } })
          this.driverTypeList = data.data
          this.editDialog.data.ridesFilter = []
        }
      },
      deep: true
    }
  },
  async beforeMount () {
    this.editDialog.data.minHoursPerRide = null
    this.brandList = await this.fetchBrands()
  },
  methods: {
    createDriverType () {
      this.editDialog.display = true
      this.editDialog.type = 'create'
      this.editDialog.data = {}

      this.editDialog.saveFunction = () => {
        if (this.$refs.driverTypeForm.validate()) {
          let body = {
            data: {
              'title': this.editDialog.data.title,
              'description': this.editDialog.data.description,
              'brand': this.editDialog.data.brand,
              'icon': this.editDialog.data.icon,
              'price': parseFloat(this.editDialog.data.price),
              'minHoursPerRide': this.editDialog.data.minHoursPerRide,
              'ridesFilter': this.editDialog.data.ridesFilter
            }
          }

          this.request('POST', `/driver-types`, body, ({ data }) => {
            this.editDialog.display = false
            this.$refs.crudTable.requestData()
            this.$notify({
              group: 'topRight',
              type: 'success',
              text: 'Driver type has been created'
            })
          })
        }
      }
    },
    editDriverType (data) {
      this.editDialog.display = true
      this.editDialog.data = this.gFunc.deepCopy(data)
      this.editDialog.type = 'edit'
      this.editDialog.saveFunction = () => {
        let body = {
          data: {
            'title': this.editDialog.data.title,
            'description': this.editDialog.data.description,
            'icon': this.editDialog.data.icon
          }
        }

        this.request('PATCH', `/driver-types/${data.id}`, body, ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Driver type has been updated'
          })

          this.editDialog.display = false
          this.$refs.crudTable.requestData()
        })
      }
    },
    deleteDriverType (id) {
      this.request('DELETE', `/driver-types/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData()
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Driver type has been deleted'
        })
      })
    },
    restoreDriverType (id) {
      this.request('PATCH', `/trash/un-delete/driver-types/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData({ deleted: 1 })
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Driver type is restored'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
